<template>
    <div>
        <div class="default_box">
            <div class="default_header" v-if="$route.query.action && data[$route.query.action]">
                <h1>{{ data[$route.query.action].title }}</h1>
            </div>
            <div class="default_box_content bg_white p15">
                <!-- <span class="info_data">Данные восстановления пароля:</span> -->
                <form @submit.stop.prevent @submit="send">
                    <div class="emailConfirmDescBox">
                        <div>
                            <span class="info_data_item"><span bold>Email: </span>{{ form.email }}</span>
                        </div>
                        <div>
                            <span class="info_data_item"><span bold>Hash: </span>{{ form.hash }}</span>
                        </div>
                        <label for="pass" class="main_input_label">Новый пароль</label>
                        <input id="pass" type="password" autocomplete="off" class="main_input" v-model="form.pass" minlength="4" required>
                        <label for="confirmPass" class="main_input_label">Повторите пароль</label>
                        <input id="confirmPass" type="password" autocomplete="off" class="main_input" v-model="form.pass_confirm" minlength="4" required>
                        <button v-if="preloader" class="default_btn" type="submit">{{ data[$route.query.action].button }}</button>
                        <PreLoadBox v-else />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "EmailConfirm",
    components: {},
    metaInfo() {
        return {
            title: `Восстановление пароля `,
            meta: [
                { name: 'description', itemprop: 'description', content: `Восстановление пароля Окей Город ${this.$myCity.name}` },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: `Восстановление пароля  - ${this.$myCity.name}` },
                { property: 'og:description', content: `Восстановление пароля Окей Город ${this.$myCity.name}` },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preloader: true,
            data: {
                'reset': {
                    title: 'Восстановление пароля',
                    button: 'Изменить пароль',
                    text_success: 'Пароль успешно изменён, теперь Вы можете авторизоваться на портале'
                },
                'mail_confirm': {
                    title: 'Подтверждение E-mail',
                    button: 'Изменить пароль',
                    text_success: 'E-mail, успешно подтверждён, теперь Вы можете авторизоваться на портале'
                },
            },
            form: {
                email: null,
                hash: null,
                pass: null,
                pass_confirm: null
            }
        }
    },
    created() {
        if (this.$route.query) {
            if (this.$route.query.email && this.$route.query.hash) {
                this.form = {
                    email: this.$route.query.email,
                    hash: this.$route.query.hash,
                    pass: null
                }
            } else {
                this.errorData();
            }
            if (this.$route.query.action === 'reset' || this.$route.query.action === 'mail_confirm') {
                if (this.$route.query.action === 'mail_confirm') {
                    this.send();
                }
            } else {
                this.errorData();
            }
        } else {
            this.errorData();
        }
    },
    methods: {
        errorData() {
            this.$notify({
                group: 'error',
                type: 'error',
                text: 'Произошла внутренняя ошибка, повторите попытку',
                title: 'Ошибка!',
                duration: 10000,
            });
            this.$router.push({ name: 'cityList' });

        },
        send() {
            if (this.$route.query === 'reset') {
                if (!this.form.pass) {
                    this.$notify({
                        group: 'error',
                        type: 'error',
                        text: 'Вы не указали пароль',
                        title: 'Ошибка!',
                        duration: 10000,
                    });
                    return;
                }

                if (!this.form.pass_confirm) {
                    this.$notify({
                        group: 'error',
                        type: 'error',
                        text: 'Вы не повторили пароль',
                        title: 'Ошибка!',
                        duration: 10000,
                    });
                    return;
                }

                if (this.form.pass !== this.form.pass_confirm) {
                    this.$notify({
                        group: 'error',
                        type: 'error',
                        text: 'Введённые пароли не совпадают',
                        title: 'Ошибка!',
                        duration: 10000,
                    });
                    return;
                }

            }

            this.preloader = false;
            let url = this.$config.api_url + 'User.emailConfirm';
            this.$http.post(url, this.form).then((response) => {
                if (response.status === 200) {
                    this.$notify({
                        group: 'error',
                        type: 'success',
                        text: this.data[this.$route.query.action].text_success,
                        title: 'Поздравляем',
                        duration: 10000,
                    });
                    this.$router.push({ name: 'cityList' });
                }
            }).finally(() => {
                this.preloader = true;
            });
        }
    },
    mounted() {}

}
</script>
<style scoped>
.info_data {
    display: block;
    color: #999;
    font-size: 18px;
    text-align: left;
}

.emailConfirmDescBox {
    /*padding: 20px 0;*/

}

.emailConfirmDescBox .info_data_item {
    display: inline-block;
    text-align: left;
    background: rgba(0, 0, 0, 0.05);
    padding: 5px;
    border-radius: 6px;
}

.emailConfirmDescBox div {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.emailConfirmDescBox div:not(:last-child) {
    margin-bottom: 10px;
}

.emailConfirmDescBox span[bold] {
    font-weight: bold;
}
</style>